var CryptoJS = require('crypto-js');

const baseURL = "http://127.0.0.1:8000";
//const baseURL =  "https://apisiangtov2.siangto.com";

export const CAPTCHA_KEY = "AQUI VA LA LLAVE DEL CAPTCHA";

export const BASE_GEOCODE_URL ="https://maps.googleapis.com/maps/api/geocode/json?key=";

export const GOOGLE_MAPS_KEY = "Llave de maps"

export const URL_PUBLICA = `${baseURL}/api/`;


export const numberWithCommas =(x) =>{
	if(x)
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	else
		return '-';
}


export const setVars = (id, Value) => {
	var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(Value), 'c273ncriPt0*-#').toString();

	sessionStorage.setItem(id, ciphertext);

	var bytes = CryptoJS.AES.decrypt(ciphertext, 'c273ncriPt0*-#');
	var originalText = bytes.toString(CryptoJS.enc.Utf8);

	return originalText;
};

export const getVars = (id, defaultvar = {}) => {
	var data = sessionStorage.getItem(id);

	var originalText = null;
	if (data !== null) {
		var bytes = CryptoJS.AES.decrypt(data, 'c273ncriPt0*-#');
		originalText = bytes.toString(CryptoJS.enc.Utf8);
	}

	return originalText !== null ? JSON.parse(originalText) : defaultvar;
};