// ** React Imports
import { Fragment, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// ** Custom Hooks

// ** Third Party Components
import Select from "react-select";

// ** Utils
// ** Third Party Components
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import {
  Facebook,
  Twitter,
  Mail,
  GitHub,
  HelpCircle,
  Coffee,
  X,
} from "react-feather";
import { DatePicker } from "reactstrap-date-picker";

/* import Flatpickr from "react-flatpickr";
import "@styles/react/libs/flatpickr/flatpickr.scss"; */
// ** Actions

// ** Context
import Logo from "../../assets_ppm/images/PPM-Logo-20222x-p-1600.png";
import { URL_PUBLICA } from "../../utils/GlobalStorage";
// ** Reactstrap Imports
import {
  Row,
  Col,
  Form,
  Input,
  Label,
  Alert,
  Button,
  CardText,
  CardTitle,
  CardHeader,
  Card,
  CardBody,
  FormGroup,
} from "reactstrap";
// ** Custom Components
import Avatar from "../../@core/components/avatar/index";
import municipios from "../../data/municipios.json";
import estados from "../../data/cat_entidad.json";
import cat_localidades from "../../data/cat_localidades.json";
import cat_colonias_ine from "../../data/cat_colonias_ine.json";

import ComponentSpinner from "../../@core/components/spinner/Loading-spinner";
import Header from "../../components/MainPage/Header";

import Footer from "../../components/MainPage/Footer";
const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#4040ff", // for selected option bg-color
    neutral10: "#4040ff", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});
const ToastContent = ({ t, name }) => {
  return (
    <div className="d-flex">
      <div className="me-1">
        <Avatar size="sm" color="success" icon={<Coffee size={12} />} />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <h6>{name}</h6>
          <X
            size={12}
            className="cursor-pointer"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
        <span>¡Te has Registrado Exitosamente!</span>
      </div>
    </div>
  );
};
const ToastContentFail = ({ t, title, message }) => {
  return (
    <div className="d-flex">
      <div className="me-1">
        <Avatar size="sm" color="danger" icon={<Coffee size={12} />} />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <h6>{title}</h6>
          <X
            size={12}
            className="cursor-pointer"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
};
const Register = () => {
  // ** Hooks
  const [loading, setLoading] = useState(false);

  const [picker, setPicker] = useState(new Date());
  const sexoOptions = [
    { value: 0, label: "Seleccionar una opción" },
    { value: 1, label: "Hombre" },
    { value: 2, label: "Mujer" },
  ];

  const militanteOptions = [
    { value: 0, label: "No" },
    { value: 1, label: "Si" },
  ];
  const vigenciaOptions = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    { value: "2029", label: "2029" },
    { value: "2030", label: "2030" },
    { value: "2031", label: "2031" },
    { value: "2032", label: "2032" },
    { value: "2033", label: "2033" }
  ];
  
  const municipioOptions = municipios;
  const estadoOptions = estados;
  const [localidadOptions, setLocalidadOptions] = useState([]);
  const [coloniasOptions, setColoniasOptions] = useState([]);
  const [CP, setCP] = useState('');
  
  const [value, setValue] = useState(new Date().toISOString());
  const [fmtValue, setFmtValue] = useState(undefined);

  const handleChange = (value, formattedValue) => {
    setValue(value);
    setFmtValue(formattedValue);
    console.log("v", value);
    console.log("f", formattedValue);
  };

  const HandleOnSubmit = (data) => {
    data.preventDefault();
    const datos = data.target;
    if (datos.nombre.value < 2 || datos.nombre.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"El nombre es obligatorio"}
        />
      ));
      return true;
    }
    if (datos.primer_apellido.value < 2 || datos.primer_apellido.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"El primer apellido es obligatorio"}
        />
      ));
      return true;
    }
    if (datos.segundo_apellido.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={
            "El segundo apellido es obligatorio, si no tienes, utiliza 'XX' en el campo"
          }
        />
      ));
      return true;
    }
    if (
      datos.fecha_nacimiento.value === "0" ||
      datos.fecha_nacimiento.value === ""
    ) {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"Debes seleccionar alguna fecha"}
        />
      ));
      return true;
    }

    if (
      datos.estado_nacimiento.value === "0" ||
      datos.estado_nacimiento.value === ""
    ) {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"Debes seleccionar alguna entidad de nacimiento"}
        />
      ));
      return true;
    }

    if (datos.sexo.value === "0") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"Debes seleccionar algún sexo"}
        />
      ));
      return true;
    }
    if (datos.clave_elector.value.length !== 18 || datos.clave_elector.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={
            "La clave de elector debe contener 18 caracteres sin espacios en blanco"
          }
        />
      ));
      return true;
    }
    if (datos.seccion_vota.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={
            "La sección es obligatoria"
          }
        />
      ));
      return true;
    }
    if (datos.militante.value !== "0" && datos.militante.value !== "1") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"Debes seleccionar si eres militante o no"}
        />
      ));
      return true;
    }
    if (datos.celular.value.length !== 10 || datos.celular.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={
            "El celular debe contener 10 dígitos sin espacios en blanco"
          }
        />
      ));
      return true;
    }
    if (datos.psswrd.value.length <= 5 || datos.psswrd.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={
            "La contraseña es obligatoria y debe contener al menos 6 caracteres sin espacios en blanco"
          }
        />
      ));
      return true;
    }
    if (datos.psswrd_confirm.value !== datos.psswrd.value) {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={
            "La contraseña y la confirmación de la contraseña no coinciden"
          }
        />
      ));
      return true;
    }
    
    if (datos.email.value < 8 || datos.email.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"El correo es obligatorio"}
        />
      ));
      return true;
    }
    
    if (datos.calle.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"La calle es obligatoria"}
        />
      ));
      return true;
    }
    if (datos.num_ext.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"El número exterior es obligatorio"}
        />
      ));
      return true;
    }
    if (datos.municipio.value === "0") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"Debes seleccionar algún municipio"}
        />
      ));
      return true;
    }
    if (datos.localidad.value === "0" || datos.localidad.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"Debes seleccionar alguna localidad"}
        />
      ));
      return true;
    }
    if (datos.colonia.value === "0" || datos.colonia.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"Debes seleccionar alguna colonia"}
        />
      ));
      return true;
    }
    if (datos.CP.value === "") {
      toast((t) => (
        <ToastContentFail
          t={t}
          title="¡No cumpliste las validaciones!"
          message={"EL Código Postal es obligatorio "}
        />
      ));
      return true;
    }
    setLoading(true);
    const dataRegister = {
      Nombre: datos.nombre.value,
      Paterno: datos.primer_apellido.value,
      Materno: datos.segundo_apellido.value,
      FechaNacimiento: datos.fecha_nacimiento.value.split("T")[0],
      usuario: datos.celular.value,
      psswrd: datos.psswrd.value,
      psswrd_confirm: datos.psswrd_confirm.value,
      TelCel: datos.celular.value,
      Correo: datos.email.value,
      Sexo: datos.sexo.value == 1 ? "H" : "M",
      
      idMunicipio: datos.municipio.value,
      idEntidadNacimiento: datos.estado_nacimiento.value,
      Militante: datos.militante.value,
      facebook: datos.facebook.value,
      instagram: datos.instagram.value,
      twitter: datos.twitter.value,
      tiktok: datos.tiktok.value,
      isApp: 0,
    };
    console.log("Data", dataRegister);
    const requestInfo = {
      method: "POST",
      body: JSON.stringify(dataRegister),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };

    fetch(`${URL_PUBLICA}setUserNoAuth`, requestInfo)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Registro inválido");
      })
      .then((tokenRS) => {
        console.log("=>tokenRS: ", tokenRS);
        if (tokenRS.success) {
          if (tokenRS.results) {
            toast((t) => (
              <ToastContent
                t={t}
                name={
                  dataRegister.Nombre +
                  " " +
                  dataRegister.Paterno +
                  " " +
                  dataRegister.Materno
                }
              />
            ));
          } else {
            toast((t) => (
              <ToastContentFail
                t={t}
                title="Ocurrió un inconveniente"
                message={tokenRS.message}
              />
            ));
          }
        } else {
          toast((t) => (
            <ToastContentFail
              t={t}
              title="Ocurrió un inconveniente"
              message={tokenRS.message}
            />
          ));
        }
      })
      .catch((e) => {
        console.log("=>error: ", e);

        toast((t) => (
          <ToastContentFail
            t={t}
            title="Ocurrió un inconveniente"
            message={e.message}
          />
        ));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header></Header>
      <div class="seccion wf-section">
        <div class="eventos-wrapper">
          <div className=" justify-content-center invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
            <Row className="justify-content-center">
              <Col
                xl="12"
                sm="12"
                className="d-flex justify-content-center text-center"
              >
                <Card /* style={{ width: "1500" }} */>
                  <CardHeader className="justify-content-center flex-column text-center">
                    <CardTitle tag="h2" className="fw-bold mb-1">
                      ¡Inicia sesión o registrate! 👋
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                  <Col className="mb-1" xl="12" md="12" sm="12">
                    
                    <Label className="form-label" for="x">
                      Si ya te registraste una vez, no es necesario registrarte
                      otra vez. Inicia sesión para inscribirte a los cursos
                    </Label>
                    <br></br>
                    <br></br>
                    <a href="/#/login" className="filter-button eventos w-button active" color="primary" >Iniciar sesión</a>
                    <br></br>
                    <br></br>
                    </Col>
                    
                    <hr></hr>
                    <h5>DATOS PERSONALES</h5>
                    <Form
                      /* style={{paddingLeft:'80pt', paddingRight:'80pt'}}  */ onSubmit={
                        HandleOnSubmit
                      }
                    >
                      <br></br>
                      <Row style={{paddingLeft:'10pt', paddingRight:'10pt'}}>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="nombre">
                          Nombre(s)
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="text"
                          class="forma-campo w-input"
                          maxlength="256"
                          name="nombre"
                          data-name="nombre"
                          placeholder="Escribe tu nombre"
                          id="nombre"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="primer_apellido">
                          1er. Apellido 
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="text"
                          class="forma-campo w-input"
                          maxlength="256"
                          name="primer_apellido"
                          data-name="primer_apellido"
                          placeholder="Escribe tu primer apellido"
                          id="primer_apellido"
                        />
                      </Col>
                      
                      <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="segundo_apellido">
                          2do. Apellido
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="text"
                          class="forma-campo w-input"
                          maxlength="256"
                          name="segundo_apellido"
                          data-name="segundo_apellido"
                          placeholder="Escribe tu segundo apellido"
                          id="segundo_apellido"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Fragment>
                          <Label className="form-label" for="default-picker">
                            Fecha Nacimiento {" "}
                          </Label>
                          <span style={{color:"red"}}>*</span>
                          <DatePicker
                            dateFormat="DD/MM/YYYY"
                            id="fecha_nacimiento"
                            name="fecha_nacimiento"
                            value={value}
                            onChange={(v, f) => handleChange(v, f)}
                          />
                        </Fragment>
                      </Col>
                      
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label">
                          Estado Nacimiento{" "}
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Select
                          name="estado_nacimiento"
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                          defaultValue={estadoOptions[0]}
                          options={estadoOptions}
                          isClearable={false}
                        />
                      </Col>
                      
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label">Sexo{" "}</Label>
                        <span style={{color:"red"}}>*</span>
                        <Select
                          name="sexo"
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                          defaultValue={sexoOptions[0]}
                          options={sexoOptions}
                          isClearable={false}
                        />
                      </Col>
                      
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="clave_elector">
                          Clave de Elector
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="text"
                          class="forma-campo w-input"
                          maxlength="18"
                          name="clave_elector"
                          data-name="clave_elector"
                          placeholder="Escribe tu clave de elector"
                          id="clave_elector"
                        />
                      </Col>
                     
                      <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label">
                          Vigencia del INE (Vencimiento)
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Select
                          id="vigencia_ine"
                          name="vigencia_ine"
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                          defaultValue={vigenciaOptions[0]}
                          options={vigenciaOptions}
                          isClearable={false}
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="seccion_vota">
                          Sección (Se encuentra en la INE)
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="number"
                          class="forma-campo w-input"
                          maxlength="4"
                          name="seccion_vota"
                          data-name="seccion_vota"
                          placeholder="Escribe tu clave de elector"
                          id="seccion_vota"
                        />
                      </Col>
                      <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label">
                          ¿Eres militante?
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Select
                          name="militante"
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                          defaultValue={militanteOptions[0]}
                          options={militanteOptions}
                          isClearable={false}
                        />
                      </Col>
                      <br></br>
                      <hr></hr>
                      <h5>DATOS DE USUARIO</h5>
                      <br></br>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="celular">
                          Celular (Usuario)
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="number"
                          class="forma-campo w-input"
                          maxlength="10"
                          id="celular"
                          data-name="celular"
                          name="celular"
                          placeholder="Escribe tu celular a 10 dígitos"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="psswrd">
                          Contraseña (al menos 6 caracteres)
                          <span style={{color:"red"}}>*</span>
                        </Label>
                        <Input
                          type="text"
                          class="forma-campo w-input"
                          id="psswrd"
                          data-name="psswrd"
                          name="psswrd"
                          placeholder="Escribe tu contraseña"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="psswrd_confirm">
                          Confirmar contraseña
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="text"
                          class="forma-campo w-input"
                          id="psswrd_confirm"
                          data-name="psswrd_confirm"
                          name="psswrd_confirm"
                          placeholder="Escribe tu contraseña"
                        />
                      </Col>

                      <br></br>
                      <hr></hr>
                      <h5>DATOS DE CONTACTO</h5>
                      <br></br>

                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="tel_fijo">
                          Telefono Fijo 
                        </Label>{" "}
                        <Input
                          type="number"
                          class="forma-campo w-input"
                          maxlength="256"
                          name="tel_fijo"
                          data-name="tel_fijo"
                          placeholder="Escribe tu telefono fijo"
                          id="tel_fijo"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="tel_mensajes">
                          Telefono de Mensajes 
                        </Label>{" "}
                        <Input
                          type="number"
                          class="forma-campo w-input"
                          maxlength="256"
                          name="tel_mensajes"
                          data-name="tel_mensajes"
                          placeholder="Escribe tu telefono de recados"
                          id="tel_mensajes"
                        />
                      </Col>
                      
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="email">
                          Correo
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          type="email"
                          class="forma-campo w-input"
                          maxlength="256"
                          name="email"
                          data-name="email"
                          placeholder="Escribe tu correo electronico"
                          id="email"
                        />
                      </Col>
                     
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="facebook">
                          Facebook 
                        </Label>
                        <Input
                          name="facebook"
                          type="text"
                          id="facebook"
                          placeholder="Escribe como apareces en facebook"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="facebook">
                          Instagram 
                        </Label>
                        <Input
                          name="instagram"
                          type="text"
                          id="instagram"
                          placeholder="Escribe como apareces en instagram"
                        />
                      </Col>

                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="twitter">
                          Twitter 
                        </Label>
                        <Input
                          name="twitter"
                          type="text"
                          id="twitter"
                          placeholder="Escribe como apareces en twitter"
                        />
                      </Col>

                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="tiktok">
                          Tiktok 
                        </Label>
                        <Input
                          name="tiktok"
                          type="text"
                          id="tiktok"
                          placeholder="Escribe como apareces en tiktok"
                        />
                      </Col>
                      <br></br>
                      <hr></hr>
                      <h5>DATOS DE DOMICILIO</h5>
                      <br></br>

                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="calle">
                          Calle
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          name="calle"
                          type="text"
                          id="calle"
                          placeholder="Escribe tu calle"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="num_ext">
                          Número Exterior
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          name="num_ext"
                          type="text"
                          id="num_ext"
                          placeholder="Escribe tu número exterior"
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="num_int">
                          Número Interior 
                        </Label>
                        <Input
                          name="num_int"
                          type="text"
                          id="num_int"
                          placeholder="Escribe tu número interior"
                        />
                      </Col>
                       <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label">
                          Municipio
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Select
                          name="municipio"
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                          defaultValue={municipioOptions[0]}
                          options={municipioOptions}
                          onChange={(municipio)=>{
                            const localidades = cat_localidades.filter(item=>item.idMunicipio === municipio.value);
                            const colonias = cat_colonias_ine.filter(item=>item.MUNICIPIO === municipio.value);
                            
                            setLocalidadOptions(localidades);
                            setColoniasOptions(colonias);
                          }}
                          isClearable={false}
                        />
                      </Col>
                       <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label">
                          Localidad
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Select
                          name="localidad"
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                          defaultValue={localidadOptions[0]}
                          options={localidadOptions}
                          isClearable={false}
                        />
                      </Col>
                       <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label">
                          Colonia
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Select
                          name="colonia"
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                          defaultValue={coloniasOptions[0]}
                          options={coloniasOptions}
                          isClearable={false}
                          onChange={(col)=>{
                            setCP(col.CP);
                          }}
                        />
                      </Col>
                     <Col className="mb-1" xl="4" md="6" sm="6">
                        <Label className="form-label" for="cod_postal">
                          Código postal
                        </Label>
                        <span style={{color:"red"}}>*</span>
                        <Input
                          name="cod_postal"
                          type="number"
                          id="cod_postal"
                          value={CP}
                          onChange={e=>{setCP(e.target.value)}}
                          placeholder="Escribe tu código postal"
                        />
                      </Col>
                      </Row>
                      <br></br>
                      {loading && <ComponentSpinner />}
                      {!loading && (
                        <Button type="submit" color="primary" block>
                          Registrarse
                        </Button>
                      )}
                      <br></br>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Register;
