
import React, { useEffect, useState } from "react";
import {
    CardText,
    Button,
    CardTitle,
    CardBody,
    CardSubtitle,
    TabContent,
    TabPane,
    Card,
  } from "reactstrap";
  
const CardEvent = ({image, titulo, tipo, info, url}) => {
    useEffect(() => {
      
        // eslint-disable-next-line
      }, []);
return (
<>

    <Card
            style={{
              width: "35rem", marginLeft: "25px", height: "650px" 
            }}
          >
            <img alt="Sample" height= "500px" src={image} />
            <CardBody>
              <div class="text-center">
                <CardTitle tag="h5">
                {titulo}
                </CardTitle>

                <CardSubtitle className="mb-2 text-muted" tag="h5">
                  {tipo}
                </CardSubtitle>
                <CardText>
                  {info}
                </CardText>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  href={url}
                  className="filter-button eventos w-button active"
                  color="primary"
                >
                  Registrarme
                </a>
              </div>
              <br></br>
            </CardBody>
          </Card>
          <br></br>
          </>)  

}
export default CardEvent;