import image1 from "../../assets_ppm/images/gallery/enero25/foto1.jpg";
import image2 from "../../assets_ppm/images/gallery/enero25/foto2.jpg";
import image3 from "../../assets_ppm/images/gallery/enero25/foto3.jpg";




import React, { useEffect, useState } from "react";
import {
    CardText,
    Button,
    CardTitle,
    CardBody,
    CardSubtitle,
    TabContent,
    TabPane,
    Card,
} from "reactstrap";

const CardEvent2 = ({ image, titulo, tipo, info, url }) => {
    useEffect(() => {
        // eslint-disable-next-line
    }, []);
    return (
        <>

            <Card
                style={{  width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px"
                    
                }}
            >
                <img alt="Sample" src={image} />
                <CardBody>
                    <div class="text-center">
                        <CardTitle tag="h5">
                            {titulo}
                        </CardTitle>

                        <CardSubtitle className="mb-2 text-muted" tag="h5">
                            {tipo}
                        </CardSubtitle>
                        <CardText>
                            {info}
                        </CardText>
                    </div>
                    <div class="text-center">
                        <a
                            target="_blank"
                            href={url}
                            
                        >
                            
                        </a>
                    </div>
                    <br></br>
                </CardBody>
            </Card>
            <br></br>

            <Card
                style={{
                    width: "370px", height: "200px", marginTop: "20px", marginLeft: "30px"
                }}
            >
                <img alt="Sample" src={image2} />
                <CardBody>
                    <div class="text-center">
                        <CardTitle tag="h5">
                            {titulo}
                        </CardTitle>

                        <CardSubtitle className="mb-2 text-muted" tag="h5">
                            {tipo}
                        </CardSubtitle>
                        <CardText>
                            {info}
                        </CardText>
                    </div>
                    <div class="text-center">
                        <a
                            target="_blank"
                            href={url}
                            
                        >
                            
                        </a>
                    </div>
                    <br></br>
                </CardBody>
            </Card>

            <Card
                style={{
                    width: "350px", height: "200px", marginTop: "20px", marginLeft: "30px"
                }}
            >
                <img alt="Sample" src={image3} />
                <CardBody>
                    <div class="text-center">
                        <CardTitle tag="h5">
                            {titulo}
                        </CardTitle>

                        <CardSubtitle className="mb-2 text-muted" tag="h5">
                            {tipo}
                        </CardSubtitle>
                        <CardText>
                            {info}
                        </CardText>
                    </div>
                    <div class="text-center">
                        <a
                            target="_blank"
                            href={url}
                            
                        >
                            
                        </a>
                    </div>
                    <br></br>
                </CardBody>
            </Card>
        </>)

}


export default CardEvent2;