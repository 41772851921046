
import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import logo_ppm from "../../assets_ppm/images/PPM_Logo_20222x.png";
function BurguerButton(props) {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color="faded" light>
        <NavbarBrand href="/" className="me-auto">
        <a href="/#/" aria-current="page" class="brand w-nav-brand w--current"><img src={logo_ppm} loading="lazy" sizes="(max-width: 991px) 103.0078125px, 100vw" alt="" class="nav-logo-img m-vil" /></a>
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
        <div class="container w-container">
          <nav role="navigation" class="nav-menu w-nav-menu">
            <a href="/#/quienes_somos" class="nav-grid-link mobile w-nav-link">Quienes somos</a>
            <a href="/#/eventos" class="nav-grid-link mobile w-nav-link">Eventos</a>
            <a href="/#/noticias" class="nav-grid-link mobile w-nav-link">Noticias</a>
            <a href="/#/convocatorias" class="nav-grid-link mobile w-nav-link">Convocatorias</a>
            <a href="/#/galerias" class="nav-grid-link mobile w-nav-link">Galerías</a>
            <a href="/#/videos" class="nav-grid-link mobile w-nav-link">Videos</a>
            <a href="/#/contacto" class="nav-grid-link mobile w-nav-link">Contacto</a>
            <a href="/#/red_sorora" class="nav-grid-link mobile w-nav-link">Red Sorora</a>
          </nav>
          <div class="menu-button w-nav-button">
            <div data-is-ix2-target="1" class="menu-movil" data-w-id="106c47ad-7f03-d639-4373-bdd906c23c79" data-animation-type="lottie" data-src="documents/93828-menu-to-close.json" data-loop="0" data-direction="1" data-autoplay="0" data-renderer="svg" data-default-duration="2" data-duration="0" data-ix2-initial-state="0"></div>
          </div>
        </div>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default BurguerButton;