import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
//import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import "./index.css";
/* import "react-datetime/css/react-datetime.css"; */
import Login from "./pages/Auth/LoginBootstrap";
import Register from "./pages/Auth/Register";
import NotFound from "./pages/Auth/NotFound";
//import Inicio from "./pages/Inicio/Inicio";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import News from "./pages/News/News";
import Convocatory from "./pages/Convocatory/Convocatory";
import Videos from "./pages/Videos/Videos";
import Events from "./pages/Events/Events";
import Contact from "./pages/Contact/Contact";
import Redsorora from "./pages/Redsorora/Redsorora";
import Galeries from "./pages/Galeries/Galeries";
// import { RviewerTrigger, Reviews } from 'React-Viewerjs';

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** i18n
/* import './configs/i18n' */

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import themeConfig from './configs/themeConfig'
import { Reviews } from "@mui/icons-material";
/* import './assets/scss/style.scss' */
const Root = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/" component={Home} />
        <Route exact path="/registro" component={Register} />
        <Route exact path="/quienes_somos" component={AboutUs} />
        <Route exact path="/eventos" component={Events} />
        <Route exact path="/noticias" component={News} />
        <Route exact path="/convocatorias" component={Convocatory} />
        <Route exact path="/galerias" component={Galeries} />
        <Route exact path="/videos" component={Videos} />
        <Route exact path="/contacto" component={Contact} />
        <Route exact path="/red_sorora" component={Redsorora} />
        <Route component={NotFound} />
      </Switch>
      <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
    </Router>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
