import React, { useEffect, useState } from "react";
import Header from "../../components/MainPage/Header";

import Footer from "../../components/MainPage/Footer";
import {
  CardText,
  Label,
  CardTitle,
  CardBody,
  CardSubtitle,
  TabContent,
  TabPane,
  Card,
  CardFooter,
  Row,
} from "reactstrap";

const divStyles2 = { backgroundColor: "white", fontSize: "3rem", color: "#6cc6ff", textAlign: "center" };
const divStyles3 = { backgroundColor: "white", fontSize: "3rem", color: "#6cc6ff", textAlign: "center" };

const Videos = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header></Header>

      <div class="seccion wf-section">
        <div class="eventos-wrapper">
          <div className="seccion-nombre">Videos</div>
          <div class="filter-wrapper" Taps>
            <a
              className={
                activeTab == "1"
                  ? "filter-button eventos w-button active"
                  : "filter-button eventos w-button"
              }
              onClick={() => setActiveTab("1")}
            >
              Videos 2022
            </a>
            <a
              className={
                activeTab == "2"
                  ? "filter-button eventos w-button active"
                  : "filter-button eventos w-button"
              }
              onClick={() => setActiveTab("2")}
            >
              Videos 2023
            </a>
          </div>
          <div class="w-dyn-list">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                


              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/eO6932vd8vI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/VJ1vfs5PTXw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/iB-214fwt08" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/MgYjmX9o4H8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/IMiEZ2L_9ek" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }}src="https://www.youtube.com/embed/8ioqob56AFU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/xCEEKqcSFL0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/gl6c1kLrJnU" title="YouTube video player"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen Loading="lazy"></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/APF2wBZizo4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/0FzThcf4rWQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/MSp6F2nBkJI" title="YouTube video player"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/pJvJo1mxVAE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/SgwX7IBejh0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/6WytArn4mq4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/PQ8Acey06jE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/KPMrCZcJOEg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/3XQzBD9ir6M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/aqbr2HbhPuw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginTop: "0px" }} src="https://www.youtube.com/embed/L4bKX0ngex8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>
              <iframe width="100" height="300" style={{ width: "470px", height: "250px", marginLeft: "20px" }} src="https://www.youtube.com/embed/ajJ3IDiyW8s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowfullscreen></iframe>

              </TabPane>
            </TabContent>

              {/*<a filter-by="2021" href="#" class="filter-button eventos w-button">Videos 2021</a>*/}
              {/*<a filter-by="2020" href="#" class="filter-button eventos w-button">Videos 2020</a>*/}
          </div>
          <div class="w-dyn-list">
            <div role="list" class="collection-list w-dyn-items">
              <div role="listitem" class="collection-item-2 w-dyn-item">
                <div class="datos-wrapper">
                  {/*<div class="datos-imagen"></div>*/}
                  <div class="datos-fecha"></div>
                  <div class="datos-pleca"></div>
                  <div class="datos-categoria"></div>
                  <div class="datos-nombre"></div>
                  <p class="datos-modalidad"></p>
                  <p class="datos-ano"></p>
                  <a href="/#/registro" class="boton w-button">Registrarme</a>
                </div>
              </div>
            </div>
            <div class="w-dyn-empty">
              <div>No items found.</div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default Videos;
