import React /* , { useEffect } */ from "react";
import Navbar from "./Navbar";
import NavbarSinAuth from "./NavbarSinAuth";
import BurguerButton from "./BurguerButton";
import { /* setVars, */ getVars } from "../../utils/GlobalStorage";
import MAIL_LOGO from "../../assets_ppm/images/Mail.svg";
import MAIL_LOGO2 from "../../assets_ppm/images/social-facebook.svg"
import MAIL_LOGO3 from "../../assets_ppm/images/Social-Twitter.svg"
import logo_ppm from "../../assets_ppm/images/PPM_Logo_20222x.png";
const Header = (props) => {
  const token = getVars("Token");

  
  return (
    <>
      {/* <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
        <div class="container w-container">
          
          <a href="/#/" aria-current="page" class="brand w-nav-brand w--current"><img src={logo_ppm} loading="lazy" sizes="(max-width: 991px) 103.0078125px, 100vw" alt="" class="nav-logo-img m-vil" /></a>
          <nav role="navigation" class="nav-menu w-nav-menu">
            <a href="/#/quienes_somos" class="nav-grid-link mobile w-nav-link">Quienes somos</a>
            <a href="/#/eventos" class="nav-grid-link mobile w-nav-link">Eventos</a>
            <a href="/#/noticias" class="nav-grid-link mobile w-nav-link">Noticias</a>
            <a href="/#/convocatorias" class="nav-grid-link mobile w-nav-link">Convocatorias</a>
            <a href="/#/galerias" class="nav-grid-link mobile w-nav-link">Galerías</a>
            <a href="/#/videos" class="nav-grid-link mobile w-nav-link">Videos</a>
            <a href="/#/contacto" class="nav-grid-link mobile w-nav-link">Contacto</a>
            <a href="/#/red_sorora" class="nav-grid-link mobile w-nav-link">Red Sorora</a>
          </nav>
          <div class="menu-button w-nav-button">
            <div data-is-ix2-target="1" class="menu-movil" data-w-id="106c47ad-7f03-d639-4373-bdd906c23c79" data-animation-type="lottie" data-src="documents/93828-menu-to-close.json" data-loop="0" data-direction="1" data-autoplay="0" data-renderer="svg" data-default-duration="2" data-duration="0" data-ix2-initial-state="0"></div>
          </div>
        </div>
      </div> */}
      <div>
      <BurguerButton></BurguerButton>
      {<div class="nav">
        <div data-w-id="ea870d35-5426-2ddd-5feb-37895a30a332" class="preloader"></div>
        <div class="nav-contacto">
          <div class="nav-wrapper superior">
            <a href="mailto:dlevy@pangto.org?subject=Contacto%20Sitio%20Web" class="footer-redes-link w-inline-block"><img src={MAIL_LOGO} loading="lazy" alt="" class="footer-redes-ico" /></a>
            <a href="https://www.facebook.com/" target="_blank" class="footer-redes-link w-inline-block"><img src={MAIL_LOGO2} loading="lazy" alt="" class="footer-redes-ico" /></a>
            <a href="https://twitter.com/ppmgto" target="_blank" class="footer-redes-link w-inline-block"><img src={MAIL_LOGO3} loading="lazy" alt="" class="footer-redes-ico" /></a>
          </div>
        </div>
        <div class="nav-wrapper">
          <a href="/#/quienes_somos" class="nav-grid-link w-inline-block">
            <div>Quienes somos</div>
          </a>
          <a href="/#/eventos" class="nav-grid-link w-inline-block">
            <div>Eventos</div>
          </a>
          <a href="/#/convocatorias" class="nav-grid-link w-inline-block">
            <div>Convocatorias</div>
          </a>
          <a href="/#/noticias" class="nav-grid-link w-inline-block">
            <div>Noticias</div>
          </a>
          <a href="/#/" aria-current="page" class="nav-grid-logo w-inline-block w--current"><img src={logo_ppm} loading="lazy" sizes="(max-width: 991px) 100vw, 136.3359375px" alt="" class="nav-logo-img" /></a>
          <a href="/#/galerias" class="nav-grid-link w-inline-block">
            <div>Galerías</div>
          </a>
          <a href="/#/videos" class="nav-grid-link w-inline-block">
            <div>Video</div>
          </a>
          <a href="/#/contacto" class="nav-grid-link w-inline-block">
            <div>Contacto</div>
          </a>
          <a href="/#/red_sorora" class="nav-grid-link w-inline-block">
            <div>Red Sorora</div>
          </a>
        </div>
      </div>}
      </div>
    </>
  );
};
export default Header;
