import React, { useEffect, useState } from "react";
import Header from "../../components/MainPage/Header";

import Footer from "../../components/MainPage/Footer";
import Paper from "@mui/material/Paper";
import Carousel from "react-material-ui-carousel";
import image1 from "../../assets/carousel/PAN_BECAS_1200X600.png";
import image2 from "../../assets/carousel/CDESILESABE.png";

const News = (props) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header></Header>
      <div class="seccion wf-section">
        <div class="eventos-wrapper">
          <div class="seccion-nombre">Noticias</div>
          <div class="w-dyn-list">
            <div role="list" class="collection-list w-dyn-items">
              <div role="listitem" class="w-dyn-item">
                <div class="datos-wrapper blog">
                  <div class="datos-imagen blog"></div>
                  <div class="datos-nombre"></div>
                  <p class="datos-modalidad grey"></p>
                  <a href="#" class="boton w-button">
                    Leer artículo
                  </a>
                </div>
              </div>
            </div>
            <div class="w-dyn-empty">
              <div>No items found.</div>
            </div>
            <div
              role="navigation"
              aria-label="List"
              class="w-pagination-wrapper paginado"
            >
              <a
                href="#"
                aria-label="Previous Page"
                class="w-pagination-previous bot-n-paginado"
              >
                <svg
                  class="w-pagination-previous-icon"
                  height="12px"
                  width="12px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewbox="0 0 12 12"
                  transform="translate(0, 1)"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    fill-rule="evenodd"
                    d="M8 10L4 6l4-4"
                  ></path>
                </svg>
                <div class="w-inline-block">Anterior</div>
              </a>
              <a
                href="#"
                aria-label="Next Page"
                class="w-pagination-next bot-n-paginado"
              >
                <div class="w-inline-block">Siguiente</div>
                <svg
                  class="w-pagination-next-icon"
                  height="12px"
                  width="12px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewbox="0 0 12 12"
                  transform="translate(0, 1)"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    fill-rule="evenodd"
                    d="M4 2l4 4-4 4"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default News;
