import React, { useEffect, useState } from "react";
import Header from "../../components/MainPage/Header";

import Footer from "../../components/MainPage/Footer";
import Paper from "@mui/material/Paper";
import CardEvent from "./Card"
import Carousel from "react-material-ui-carousel";
import image1 from "../../assets/carousel/PAN_BECAS_1200X600.png";
import image2 from "../../assets/carousel/CDESILESABE.png";
import imgflayer01 from "../../assets_ppm/images/flayer-home/mujeresdeaccionguanajuato.jpeg"
import imgflayer02 from "../../assets_ppm/images/flayer-home/mujeresenelpoder.jpeg"

import img0001 from "../Events/2023/flayer_0001_lahistoriadelaparticipacionpoliticadelasmujeres.jpeg";
import img0002 from "../Events/2023/flayer_0002_lamujerylapolitica.png";
import img0003 from "../Events/2023/flayer_0003_porquelasmujeresdebemosparticiparenlapolitica.jpeg";
import img0004 from "../Events/2023/flayer_0004_negociacionmanejodecrisisenlapalitica.jpeg";
import img0005 from "../Events/2023/flayer_0005_lamujerylapolitica.jpeg";
import img0006 from "../Events/2023/flayer_0006.jpeg";
import img0007 from "../Events/2023/flayer_0007.jpeg";
import img0008 from "../Events/2023/flayer_0008.jpeg";
import img0009 from "../Events/2023/flayer_0009.jpeg";
import img0011 from "../Events/2023/flayer_0011_lamujerylapolitica.jpeg";
import img0012 from "../Events/2023/flayer_0012_mujeresenelpoder.jpeg";
import img0013 from "../Events/2023/flayer_0013_tallerparaprevenir.jpeg";
import img0014 from "../Events/2023/flayer_0014_contruyendoliderazgos.jpeg";
import img0015 from "../Events/2023/flayer_0015_contruyendoliderazgospoliticos.jpeg";
import img0016 from "../Events/2023/flayer_0016_seminario_marketing_comunicacion.jpeg";
import img0017 from "../Events/2023/flayer_0017_construyendoliderazgospoliticos_PNL.jpeg";
import img0018 from "../Events/2023/flayer_0018_construyendoliderazgospoliticos_motivacion.jpeg";
import img0019 from "../Events/2023/flayer_0019_talleroratorianegociacionymanejodecrisis.jpeg";
import img001602 from "../Events/2023/flayer_0016_02_Seminario_Marketing_Comunicacion_Politica_para_Mujeres.jpeg";
import img0020 from "../Events/2023/flayer_0020_Taller_para_prevenir_atender_erradicar_violencia_politica.jpeg";
import img0021 from "../Events/2023/flayer_0021_La_Mujer_y_la_politica.jpeg";
import img0022 from "../Events/2023/flayer_0022_contruyendo_liderazgos_politicos_mujeres.jpeg";
import img0023 from "../Events/2023/flayer_0023_Mujeres_Politicas_Accion.jpeg";
import img002302 from "../Events/2023/flayer_0023_02_mujeres_accion_evento_cerrado.jpeg";
import img0024 from "../Events/2023/flayer_0024_LamujeryLapolitica1.jpeg";
import img0025 from "../Events/2023/flayer_0025_lamujerylapolitica_02.jpeg";
import img0026 from "../Events/2023/flayer_0026_perspectivadegeneroenaccionnacional.jpeg";
import img0027 from "../Events/2023/flayer_0027_lamujerylapolitica.jpeg";
import img0028 from "../Events/2023/flayer_0028_historiadelaparticipacionpoliticamujer.jpeg";
import img0029 from "../Events/2023/flayer_0029_porquelamujerdebeparticiparenpolitica.jpeg";


import img0010 from "../Events/2023/flayer_0010.jpeg";
import {
  CardText,
  Button,
  CardTitle,
  CardBody,
  CardSubtitle,
  TabContent,
  TabPane,
  Card,
  Row,
} from "reactstrap";
const Home = (props) => {
  const images = [image1, image2];

  var items = [];
  for (let i = 0; i < images.length; i++) {
    items.push(<Item image={images[i]} name={"ITEM_" + i} link=""></Item>);
  }
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header></Header>
      <div
        data-delay="4000"
        data-animation="fade"
        class="hero-slider w-slider"
        data-autoplay="true"
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="false"
        data-autoplay-limit="0"
        data-nav-spacing="10"
        data-duration="500"
        data-infinite="true"
      >
        <div class="w-slider-mask">
          { /* <div class="hero-slide w-slide">
            <div class="hero-slide-wrapper">
              <div class="hero-slide-evento"></div>
              <div class="linea-hor"></div>
              <h1 class="heading"></h1>
              <a href="https://www.facebook.com/PPMGuanajuatoMx" target="_blank" class="button w-button">Visitar</a>
            </div>
           </div> */}
          <div class="hero-slide _2 w-slide">
            <div class="hero-slide-wrapper">
              { /* <div class="hero-slide-evento"></div> */}
              { /* <div class="linea-hor"></div> */}
              <h1 class="heading"></h1>
              { /* <a href="https://www.facebook.com/PPMGuanajuatoMx" target="_blank" class="button w-button">Visitar</a> */}
            </div>
          </div>
          <div class="hero-slide _3 w-slide">
            <div class="hero-slide-wrapper">
              <div class="hero-slide-evento"></div>
              { /* <div class="linea-hor"></div> */}
              <h1 class="heading"></h1>
              <a href="https://indd.adobe.com/view/957d12dc-785f-4186-af00-b898192f6ab7" target="_blank" class="button w-button">Descargar Libro</a>
            </div>
          </div>

        </div>
        <div class="left-arrow w-slider-arrow-left">
          <div class="w-icon-slider-left"></div>
        </div>
        <div class="right-arrow w-slider-arrow-right">
          <div class="w-icon-slider-right"></div>
        </div>
        <div class="hero-slide-nav w-slider-nav w-slider-nav-invert w-round"></div>
      </div>
      <div class="eventos wf-section">
        <div class="eventos-wrapper">
        <Row margin="25px">
            <CardEvent url={"https://forms.gle/qKVKUS2qpJ3iYLpW6"} info={" Sabado 07 Octubre - Registro: 09:30 am - Inicia: 10:30 am"} tipo={"Presencial"} image={img0029} titulo={"¿Porqué las mujeres debemos participar en la política? "}></CardEvent>

          </Row><br></br>

        <Row margin="25px">
            <CardEvent url={"https://forms.gle/sszxiEmRN8DzSkMh7"} info={" Sabado 15 Julio - Registro: 09:00 am - Inicia: 10:00 am"} tipo={"Presencial"} image={img0027} titulo={"La Mujer y la Política, Mujeres haciendo Política "}></CardEvent>
            <CardEvent url={"https://forms.gle/cAQMwed6pN9PPQkU8"} info={" Sabado 30 Septiembre - Registro: 09:30 am - Inicia: 10:30 am"} tipo={"Presencial"} image={img0028} titulo={"Historia de la participación Política de las mujeres "}></CardEvent>

          </Row><br></br>

        <Row margin="25px">
            <CardEvent url={"https://forms.gle/d73iP38EyHSYbTYa8"} info={" Sabado 01 Julio - Registro: 09:00 am - Inicia: "} tipo={"Presencial"} image={img0025} titulo={"La Mujer y la Política, Planeación Estratégica para Mujeres Políticas"}></CardEvent>
            <CardEvent url={"https://forms.gle/hSTNkgj3DXGN426j6"} info={" 28 y 29 de Junio 2023 - Inicia: 05:00 pm "} tipo={"Virtual"} image={img0026} titulo={"Perspectiva de Género en Acción Nacional"}></CardEvent>

          </Row><br></br>

        <Row margin="25px">
            <CardEvent url={"https://forms.gle/t6eSnmnyZqnEj4pM8"} info={" Viernes 4pm a 8pm - Inicia 01 Junio - Termina 20 Julio"} tipo={"Presencial"} image={img002302} titulo={"Seminario Mujeres Políticas en Acción"}></CardEvent>
            <CardEvent url={"https://forms.gle/GVB7n3owQo6fB3Dd8"} info={" Sabado 10 junio - Registro: 09:00 am - Inicia: 10:00 am "} tipo={"Presencial"} image={img0024} titulo={"La Mujer y la Política, Introducción a la Planeación Estratégica"}></CardEvent>

          </Row><br></br>

        <Row margin="25px">
            <CardEvent url={"https://forms.gle/FBwAyem1YvQKjj6dA"} info={" Sábado 27 de Mayo 2023 - Registro: 10:30 am - Inicia 11:30 am"} tipo={"Presencial"} image={img0021} titulo={"La Mujer y la Política, Privacidad de datos personales"}></CardEvent>
            <CardEvent url={"https://forms.gle/3b16BZduzBivpRD68"} info={" Sábado 3 de Junio 2023 - Registro: 10:00 am - Inicia 11:00 am"} tipo={"Presencial"} image={img0022} titulo={"Construyendo Liderazgos Políticos de las Mujeres"}></CardEvent>

          </Row><br></br>

          <Row margin="25px">
            <CardEvent url={"https://forms.gle/cXu9vUuGAEfCQsy98"} info={" Sábado 20 de Mayo 2023 - Registro: 09:30 am - Inicia 10:30 am"} tipo={"Presencial"} image={img0019} titulo={"Taller oratoria, negociación y manejo de crisis en la política para la mujer, Oratoria Política"}></CardEvent>
            <CardEvent url={"https://forms.gle/A7S2MXVtNay1ojjXA"} info={" Jueves 25 de Mayo 2023 - Registro: 04:30 pm - Inicia 05:00 pm"} tipo={"Presencial"} image={img0020} titulo={"Taller para Prevenir, Atender y Erradicar la Violencia Política en Razón de Género"}></CardEvent>
          </Row><br></br>


          <Row margin="25px">

            <CardEvent url={"https://forms.gle/4psQqcnJm4XMgHJJA"} info={" Sábado 06 de Mayo 2023 - Registro: 10:00 am - Inicia 11:00 am"} tipo={"Presencial"} image={img0017} titulo={"Construyendo liderazgos políticos de las mujeres, Técnicas de PNL para ganar elecciones"}></CardEvent>
            <CardEvent url={"https://forms.gle/9zqukTckFqtbBhQW7"} info={" Sábado 13 de Mayo 2023 - Registro: 10:00 am - Inicia 11:00 am"} tipo={"Presencial"} image={img0018} titulo={"Contruyendo Liderazgos Políticos de las Mujeres, Como mantener la motivación en la gente"}></CardEvent>

          </Row><br></br>


          <Row margin="25px">

            <CardEvent url={"https://forms.gle/DLTCJSNqLpGnznPw9"} info={" Sábado 29 de Abril 2023 - Registro: 10:00 am - Inicia 11:00 am"} tipo={"Presencial"} image={img0015} titulo={"Contruyendo Liderazgos Políticos de las Mujeres, La Neurociencia detrás del Poder"}></CardEvent>
            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" width="500px" height="400px" marginTop="20px" src={img001602} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    Seminario Marketing y Comunicación Política para Mujeres
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial / Virtual
                  </CardSubtitle>
                  <CardText>
                    Viernes 4pm a 8pm - Inicia 12 Mayo - Termina 9 Junio
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/BUvbo4CxUf9574aw6"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div><br></br>
                <div class="text-center" >
                  <a
                    target="_blank"
                    href="https://docdro.id/85N5Jqd"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    DESCARGAR CONVOCATORIA
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>


          </Row><br></br>

          <Row margin="25px">

            <CardEvent url={"https://forms.gle/vHjGrfRh95Svocvb7"} info={" Martes 25 de Abril 2023 - Registro: 04:30 pm - Inicia 05:30 pm"} tipo={"Presencial"} image={img0013} titulo={"Taller para prevenir, Atender y Erradicar la Violencia Politica  en Razón de Género"}></CardEvent>
            <CardEvent url={"https://forms.gle/pbpDKaArqSNTBDey6"} info={" Sábado 22 de Abril 2023 - Registro: 10:00 am - Inicia 11:00 am"} tipo={"Presencial"} image={img0014} titulo={"Contruyendo Liderazgos Políticos de las Mujeres"}></CardEvent>

          </Row><br></br>


          <Row margin="25px">

            <CardEvent url={"https://forms.gle/YLhR2AyiRqq1bHpL6"} info={" Sábado 25 de marzo 2023 - Registro: 04:45 pm - Inicia 05:00 am"} tipo={"Presencial"} image={img0011} titulo={"La mujer y la política, Violencia política con razón de género"}></CardEvent>

            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" width="500px" height="400px" marginTop="20px" src={img0012} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    Presentación del libro, Mujeres en el Poder
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Sábado 25 de marzo 2023 - Registro: 10:00 am - Inicia 11:00 am
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/E1ngfxy8Hi26W7XQ6"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <div class="text-center" >
                  <a
                    target="_blank"
                    href="https://indd.adobe.com/view/957d12dc-785f-4186-af00-b898192f6ab7"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    DESCARGAR LIBRO
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>

          </Row><br></br>

          <Row margin="25px">

            <CardEvent url={"https://forms.gle/xGw8aCYu6qv4TGrE9"} info={" Sábado 11 de marzo 2023 - Registro: 09:30 am - Inicia 10:30 am"} tipo={"Presencial"} image={img0010} titulo={"Taller para Prevenir, Atender y Erradicar la Violencia Política en Razón de Género"}></CardEvent>


            <Card
              style={{
                width: "35rem", marginLeft: "25px"
              }}
            >
              <img alt="Sample" width="500px" height="500px" marginTop="30px" src={img0009} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    Taller Oratoria Negociación y Manejo de Crisis en la Política para la Mujer:
                    Negociación y Manejo de Crisis
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Sábado 18 de marzo 2023 - Registro: 09:30 am - Inicia 10:30
                    am
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/mwXkH2xyKT7oyQNw6"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>


          </Row>

          <br></br>

          <Row margin="25px">

            <Card
              style={{
                width: "35rem", marginLeft: "25px"
              }}
            >
              <img alt="Sample" width="500px" height="500px" marginTop="30px" src={img0008} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    La Mujer y la Política: Mujeres Emprendedoras y de Liderazgo
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Jueves 16 de marzo 2023 - Registro: 05:30 pm - Inicia 06:00
                    pm
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/ioxVjtzoe7nwbDL67"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>





            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" width="500px" height="500px" marginTop="20px" src={img0007} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    La Mujer y la Política: La Mujer en la Política Moviendo a la Sociedad
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Lunes 13 de marzo 2023 - Registro: 05:00 pm - Inicia 05:30
                    pm
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/UwuvvmyCcDRC9psC8"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>


          </Row>

          <br></br>

          <Row margin="25px">

            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" src={img0006} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    Contruyendo Liderazgos Políticos de las Mujeres: Mujer Política, ¿hasta dónde? quieres llegar
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Sábado 4 de marzo 2023 - Registro: 10:00 am - Inicia 11:00
                    am
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/TQ9Ry9e96ghW7aJH6"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>

            <br></br>






            <Card

              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" src={img0005} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    La Mujer y la Política (Liderazgo Político de la Mujer)
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Sábado 25 de febrero 2023 - Registro: 05:40 pm - Inicia 06:00
                    pm
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/3263YAyJM1s8GAUC6"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>
          </Row>


          <br></br>

          <Row margin="25px">

            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" src={img0004} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    Negociación y Manejo de Crisis en la Política para la Mujer
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Sábado 25 de febrero 2023 - Registro: 09:30 am - Inicia 10:30
                    am
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/t2Xgirinmwz7y6jNA"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>
            <br></br>
            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" src={img0003} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    ¿Por qué las Mujeres debemos participar en la Política
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Sábado 18 de febrero 2023 - Registro: 09:30 am - Inicia 10:30
                    am
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/r9Eff7rwb2rUrwFv8"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>
          </Row>

          <br></br>

          <Row margin="25px">


            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" src={img0002} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">La Mujer y la Política</CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Lunes 13 de febrero 2023 - Registro: 5:00 pm - Inicia 5:30 pm
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/LeaD5E4TzvyKhxGq5"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>
            <br></br>
            <Card
              style={{
                width: "35rem", marginLeft: "25px",
              }}
            >
              <img alt="Sample" src={img0001} />
              <CardBody>
                <div class="text-center">
                  <CardTitle tag="h5">
                    La Historia de la Participación Política de las Mujeres
                  </CardTitle>

                  <CardSubtitle className="mb-2 text-muted" tag="h5">
                    Presencial
                  </CardSubtitle>
                  <CardText>
                    Sábado 28 de enero 2023 - Registro: 9:00 am a 9:45 am - Inicia
                    10:00 am
                  </CardText>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    href="https://forms.gle/a9wUa6TLaz9rBYut5"
                    className="filter-button eventos w-button active"
                    color="primary"
                  >
                    Registrarme
                  </a>
                </div>
                <br></br>
              </CardBody>
            </Card>
          </Row>

        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

function Item({ image, name, link }) {
  console.log(image);
  return (
    <Paper className="item">
      <img
        width={"25%"}
        height={200}
        className="imageCarousel"
        src={image}
        alt={name}
      />
      <img
        width={"25%"}
        height={200}
        className="imageCarousel"
        src={image}
        alt={name}
      />
      <img
        width={"25%"}
        height={200}
        className="imageCarousel"
        src={image}
        alt={name}
      />
      <img
        width={"25%"}
        height={200}
        className="imageCarousel"
        src={image}
        alt={name}
      />
    </Paper>
  );
}

export default Home;
