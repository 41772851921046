import React from "react";
import Header from "../../components/MainPage/Header";

import Footer from "../../components/MainPage/Footer";

const Contacto = (props) => {



  return (
    <>
      <Header></Header>

      <div class="seccion wf-section">
        <div class="eventos-wrapper">
          <div class="seccion-nombre">Contacto</div>
          <div class="filter-wrapper">
            {/* <a filter-by="2022" href="#" class="filter-button eventos w-button">Eventos 2022</a>
        <a filter-by="2021" href="#" class="filter-button eventos w-button">Eventos 2021</a>
        <a filter-by="2020" href="#" class="filter-button eventos w-button">Eventos 2020</a> */}
          </div>

          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Apaseo El Alto</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/esKHxv2FvVqDa4W28" target="_blank" class="mapa-grid-texto link">C. 5 De Mayo #424,<br />Zona Centro; C.P. 38500</a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524131660830" class="mapa-grid-texto link">413 166 08 30</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-apaseoalto@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-apaseoalto@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Apaseo El Grande</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/2jfkhDtA9itTxGQy8" target="_blank" class="mapa-grid-texto link">Prolongación Mariano Abasolo No. 307. Zona Centro; C.P. 38160<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524131583512" class="mapa-grid-texto link">413 158 35 12</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-apaseogrande@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-apaseogrande@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Atarjea</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/vQGpwMwHMebQfin87" target="_blank" class="mapa-grid-texto link">Comunidad Mangas <br />Cuatas S/N; C.P. 37946<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524426885956" class="mapa-grid-texto link">442 688 59 56</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-atarjea@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-atarjea@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Celaya</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/ymdgvAEaDZV17E3dA" target="_blank" class="mapa-grid-texto link">Eje Manuel J.Clouthier 551, <br />Fracc La Esperanza; C.P. 38020<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524611740696" class="mapa-grid-texto link">461 174 06 96</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-celaya@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-celaya@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Comonfort</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/J6haGBGitS1GQdVN7" target="_blank" class="mapa-grid-texto link">Ignacio Comonfort#195 <br />Carretera Norte San Miguel De Allende; C.P. 38204<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524121563494" class="mapa-grid-texto link">412 156 34 94</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-comonfort@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-comonfort@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Coroneo</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/dEn1goEgvkrPSQct6" target="_blank" class="mapa-grid-texto link">Blvd. Jose Velazquez Zepeda 173-A, Col Guanajuato, Cp 38509<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524211069039" class="mapa-grid-texto link">421 106 90 39</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-coroneo@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-coroneo@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Cortazar</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/UUEbootgx5Wwv8GQ9" target="_blank" class="mapa-grid-texto link">Manuela Zardaneta 100, Burócrata: C.P. 38249<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524111600964" class="mapa-grid-texto link">411 160 09 64</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-cortazar@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-cortazar@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Cuerámaro</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/2mRdGWJR21mFDV1f8" target="_blank" class="mapa-grid-texto link">Francisco Venegas #601, <br />Zona Centro; C.P 36960<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524296942512" class="mapa-grid-texto link">429 694 25 12</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-cueramaro@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-cueramaro@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Doctor Mora</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/ePLJiN7vk2DTT2ky9" target="_blank" class="mapa-grid-texto link">C. Silao # 19 B <br />Col. Zona Centro C.P 37960<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524191930671" class="mapa-grid-texto link">419 193 06 71</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-doctormora@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-doctormora@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Dolores Hidalgo</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/baBE4QR5wUXVcdtG7" target="_blank" class="mapa-grid-texto link">Av. Manuel J.Clouthier #5, Esq.  Manuel Gómez MorinFracc. Siglo XXI; C.P. 37800<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524181827081" class="mapa-grid-texto link">418 182 70 81</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-doloreshidalgo@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-doloreshidalgo@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Guanajuato</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/Aa1tvfec6JpnVp3Z8" target="_blank" class="mapa-grid-texto link">Local D Número 1 Del Conjunto Comercial Villas Manchegas; C.P. 36251<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524737335289" class="mapa-grid-texto link">473 733 52 89</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-guanajuato@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-guanajuato@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Irapuato</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/bJoiTkFeLdoaSMH4A" target="_blank" class="mapa-grid-texto link">C. Niebla 358 Col. Las Reinas C.P.36660<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524626244729" class="mapa-grid-texto link">462 624 47 29</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-irapuato@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-irapuato@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Jaral Del Progreso</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/mZR9hmiVkjcQkQXX6" target="_blank" class="mapa-grid-texto link">Hidalgo 307, Zona Centro; C.P. 38470<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524116612072" class="mapa-grid-texto link">411 661 20 72</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cmjaralp@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cmjaralp@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Jerecuaro</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/3NW7H6X3chC57fXj9" target="_blank" class="mapa-grid-texto link">C. 5 De Mayo # 18Col. Centro CP. 38540<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524214760974" class="mapa-grid-texto link">421 476 09 74</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-jerecuaro@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-jerecuaro@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">León</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/nmSgdfANgFWhiVtL9" target="_blank" class="mapa-grid-texto link">Blvd. Jorge Vertíz Campero # 195 Esq. Con Vicente Valtierra, Col. San Pedro De Los Hernández; C.P.37280<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524774705444" class="mapa-grid-texto link">477 470 54 44</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdmleon@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdmleon@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Moroleón</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/B8QeGKb5SpP7cocB8" target="_blank" class="mapa-grid-texto link">Dr. David García De Alba #134 Esq. Abasolo, Col. El Llanito: C.P. 38817<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524454585755" class="mapa-grid-texto link">445 458 57 55</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-moroleon@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-moroleon@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Ocampo</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/xQZUuR54a6hTXWJU7" target="_blank" class="mapa-grid-texto link">Priv. 5 De Mayo #227, Zona Centro; C.P. 37630<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524286830606" class="mapa-grid-texto link">428 683 06 06</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-ocampo@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-ocampo@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Pénjamo</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/rmVipUV9x2CqAWbJ9" target="_blank" class="mapa-grid-texto link">1° De Mayo 34, Zona Centro; C.P. 36900<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524696908269" class="mapa-grid-texto link">469 690 82 69</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-penjamo@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-penjamo@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Pueblo Nuevo</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/hkiVu749WkLmaq4X6" target="_blank" class="mapa-grid-texto link">Aldama 212-A, Zona Centro; C.P. 36890<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524296950745" class="mapa-grid-texto link">429 695 07 45</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-pueblonuevo@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-pueblonuevo@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Purísima Del Rincón</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/NATUGeKZVBbLjiGf8" target="_blank" class="mapa-grid-texto link">C. Flamingo 101, Esq. con Plácido Domingo, Col. San Jorge; C.P. 36400<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524767435641" class="mapa-grid-texto link">476 743 56 41</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-purisimar@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-purisimar@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Romita</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/Qz6MY1ZPu4siAna5A" target="_blank" class="mapa-grid-texto link">Priv. Vargas No. 15, Fracc. Privada Vargas<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524767435641" class="mapa-grid-texto link">476 743 56 41</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-romita@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-romita@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Salamanca</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/UrFJsmfrkV7PUWhE7" target="_blank" class="mapa-grid-texto link">Río Lerma 210, Zona Centro; C.P. 36700<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524646473117" class="mapa-grid-texto link">464 647 31 17</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-salamanca@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-salamanca@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Salvatierra</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/4rXUhjYKK3XTo2Zv6" target="_blank" class="mapa-grid-texto link">Blvd. Juan Jesús Posadas Ocampo 119, Col San Buenaventura; C.P. 38900<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524666634265" class="mapa-grid-texto link">466 663 42 65</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-salvatierra@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-salvatierra@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">San Diego de la Unión</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/ssRRWExwj36hA4bH7" target="_blank" class="mapa-grid-texto link">5 De Mayo 9 -B, Zona Centro; C.P. 37850<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524186841084" class="mapa-grid-texto link">418 684 10 84</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-sandiegou@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-sandiegou@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">San Felipe</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/STCy4GKEaye7d77FA" target="_blank" class="mapa-grid-texto link">Carretera San Felipe- Dolores No. 929; C.P. 37600<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524286851003" class="mapa-grid-texto link">428 685 10 03</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-sanfelipe@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-sanfelipe@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">San Francisco del Rincón</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/ZBQ77vyFFzBXx82b8" class="mapa-grid-texto link">Potrero Esq. Matamoros S/N, Infonavit; C.P.36330<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524767435720" class="mapa-grid-texto link">476 743 57 20</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-sanfrancisco@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-sanfrancisco@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">San José Iturbide</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/4Jh9guS9sFHbnsMK8" target="_blank" class="mapa-grid-texto link">Vasco De Gama 19,Fracc. Arboledas; C.P. 37980<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524192341405" class="mapa-grid-texto link">419 234 14 05</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-sanjosei@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-sanjosei@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">San Luis de La Paz</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/91WYBEx6cjdxaJma9" target="_blank" class="mapa-grid-texto link">Calle Abasolo # 103,Col. Buenavista<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524686885115" class="mapa-grid-texto link">468 688 51 15</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-sanluispaz@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-sanluispaz@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">San Miguel de Allende</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/w73hwq8NdaAfksJJ9" target="_blank" class="mapa-grid-texto link">Salida Real a Queretaro #189 (Plaza Primavera)<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524151545317" class="mapa-grid-texto link">415 154 53 17</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-allende@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-allende@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Santa Catarina</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/rNBCLweNWbWSd1Hq7" target="_blank" class="mapa-grid-texto link">Calle Santa Mónica #4, Col El Tablon, Cp. 37950<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524191054183" class="mapa-grid-texto link">419 105 41 83</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-santacatarina@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-santacatarina@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Santa Cruz de Juventino Rosas</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/CB5jSouU4J4riTUj7" target="_blank" class="mapa-grid-texto link">Av. Guanajuato # 87, Col Magisterial; C.P. 38240<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524126900879" class="mapa-grid-texto link">412 690 0879</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-scjuventinor@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-scjuventinor@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Santiago Maravatio</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/26puvoMK3cxgWVQc9" target="_blank" class="mapa-grid-texto link">Blvd. 25 De Julio 1540#39, Zona Centro; C.P. 38970<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524664510500" class="mapa-grid-texto link">466 451 05 00</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-stgomaravatio@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-stgomaravatio@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Tarandacuao</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/ucWq4p3ybQZRzxkn9" target="_blank" class="mapa-grid-texto link">C. Capitán Pedro Campos# 892, Col. Los Cipreses; C.P. 38790<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524214741364" class="mapa-grid-texto link">421 474 13 64</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-tarandacuao@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-tarandacuao@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Tarimoro</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/1rVXHepSpKvtPowCA" class="mapa-grid-texto link">Libramiento Sur # 21 Col Las Flores: C.P. 38700<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524774015375" class="mapa-grid-texto link">477 401 53 75</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-tarimoro@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-tarimoro@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Tierra Blanca</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/Mow9821UbhkLh3pQA" target="_blank" class="mapa-grid-texto link">Emilio Portes Gil #84 , Zona Centro; C.P. 37970<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524196903019" class="mapa-grid-texto link">419 690 3019</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-tierrablanca@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-tierrablanca@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Uriangato</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/2zx1JWL27hiybiiKA" target="_blank" class="mapa-grid-texto link">Blvd. Benito Juárez #213, Col. Lázaro Cárdenas; C.P. 38980<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524454578681" class="mapa-grid-texto link">445 457 86 81</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-uriangato@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-uriangato@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Victoria</div>
              </div>
              <div class="w-col w-col-3">
                <a href="#" class="mapa-grid-texto link">Prolongacion San Juan S/N, Zona Centro; C.P. 37920<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524192939182" class="mapa-grid-texto link">419 293 91 82</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-sanjosei@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-victoria@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Villagrán</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/o1DLvf8X9V1oXW9r9" target="_blank" class="mapa-grid-texto link">Caoba 1 Esq. Agrarismo Col. La Arboleda; C.P. 38260<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524111651896" class="mapa-grid-texto link">411 165 18 96</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-villagran@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-villagran@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Xichú</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/GJNCvg3e2nWjabk76" target="_blank" class="mapa-grid-texto link">Calle 5 De Mayo #1 ,Col Centro, CP.37930<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524192941240" class="mapa-grid-texto link">419 294 12 40</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-xichu@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-xichu@pangto.org</a>
              </div>
            </div>
          </div>
          <div class="contacto-datos">
            <div class="w-row">
              <div class="w-col w-col-2">
                <div class="mapa-grid-texto bold">Yuriria</div>
              </div>
              <div class="w-col w-col-3">
                <a href="https://goo.gl/maps/eM9VwrTe5vWn37Z96" target="_blank" class="mapa-grid-texto link">Prolongación 5 de Mayo #240, Col. La Aldea: C.P. 38940<br /></a>
              </div>
              <div class="w-col w-col-2"></div>
              <div class="w-col w-col-2">
                <a href="tel:+524456884651" class="mapa-grid-texto link">445 688 46 51</a>
              </div>
              <div class="w-col w-col-3">
                <a href="mailto:cdm-yuriria@pangto.org?subject=Contacto%20Sitio%20PPM" class="mapa-grid-texto link">cdm-yuriria@pangto.org</a>
              </div>
            </div>
          </div>
        </div>
        <div class="mapa-movil w-embed w-iframe">

       {/*  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119125.16681135944!2d-101.63650573388146!3d21.086179258999003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bbddef9dcc8df%3A0x2dd75073ae8862c2!2sMunicipal%20Institute%20for%20Women%20Leon!5e0!3m2!1sen!2smx!4v1649722522081!5m2!1sen!2smx" width="340" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 */}
        </div>
        <div class="seccion-intro">¿Tienes alguna duda o comentario? Déjanos un mensaje</div>
        <div class="w-form">
          <form id="wf-form-Forma-Contacto" name="wf-form-Forma-Contacto" data-name="Forma Contacto" method="get">

            <div class="form-columns w-row">
              <div class="form-column-l w-col w-col-6"><input type="text" class="forma-campo w-input" maxlength="256" name="Nombre" data-name="Nombre" placeholder="Nombre" id="Nombre" required="" /><input type="text" class="forma-campo w-input" maxlength="256" name="Nombre-2" data-name="Nombre 2" placeholder="Nombre" id="Nombre-2" required="" />
                <input type="tel" class="forma-campo w-input" maxlength="256" name="N-mero-de-tel-fono" data-name="Número de teléfono" placeholder="Número de teléfono" id="N-mero-de-tel-fono" required="" /> </div>
              <div class="form-column-r w-col w-col-6"><textarea id="Mensaje" name="Mensaje" maxlength="5000" data-name="field" placeholder="Mensaje" required="" class="forma-campo _135px w-input"></textarea><input type="submit" value="Enviar datos" data-wait="Espere un momento..." class="boton _15margin w-button" /></div>
            </div>
          </form>
          <div class="form-success w-form-done">
            <div class="form-success-wrapper">
              <div class="form-lottie" data-w-id="b875f70c-3c46-846d-6d36-a11ea1a7352c" data-animation-type="lottie" data-src="documents/98741-success.json" data-loop="0" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="2" data-duration="0"></div>
              <div class="seccion-nombre centrado">¡Gracias! Tu mensaje fue enviado</div>
            </div>
          </div>
          <div class="w-form-fail">
            <div>Ups! Al parecer hubo un error al enviar la forma, por favor intenta nuevamente en unos segundos.</div>
          </div>
        </div>
      </div>


      {/* <div class="w-dyn-list">
        <div role="list" class="collection-list w-dyn-items">
          <div role="listitem" class="collection-item-2 w-dyn-item">
            <div class="datos-wrapper">
              <div class="datos-imagen"></div>
              <div class="datos-fecha"></div>
              <div class="datos-pleca"></div>
              <div class="datos-categoria"></div>
              <div class="datos-nombre"></div>
              <p class="datos-modalidad"></p>
              <p class="datos-ano"></p>
              <a href="/#/registro" class="boton w-button">Registrarme</a>
            </div>
          </div>
        </div>
        <div class="w-dyn-empty">
          <div>No items found.</div>
        </div>
      </div> */}
  <Footer></Footer>
    </>
  );
}
export default Contacto;
