import React, { useEffect, useState } from "react";
import Header from "../../components/MainPage/Header";
import CardEvent from "../Home/Card"

import Footer from "../../components/MainPage/Footer";
import Paper from "@mui/material/Paper";
import Carousel from "react-material-ui-carousel";
import image1 from "../../assets/carousel/PAN_BECAS_1200X600.png";
import image2 from "../../assets/carousel/CDESILESABE.png";

const divStyles4 = { backgroundColor: "white", fontSize: "3rem", color: "#6cc6ff" };
const divStyles6 = { backgroundColor: "transparent", fontSize: "2rem", color: "#9e1fbc", textAlign: "center", marginTop: "15px" };


import {
  CardText,
  Label,
  CardTitle,
  CardBody,
  CardSubtitle,
  TabContent,
  TabPane,
  Card,
  CardFooter,
  Row,
} from "reactstrap";

const News = (props) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header></Header>
      <div class="seccion wf-section">
        <div class="seccion-wrapper">
          <div class="seccion-nombre">Convocatoria</div>
          <div class="filter-wrapper" tabs>
            <a
              className={
                activeTab == "1"
                  ? "filter-button convocatoria w-button active"
                  : "filter-button convocatoria w-button"
              }
              onClick={() => setActiveTab("1")}
            >
              2023
            </a>
            <a
              className={
                activeTab == "2"
                  ? "filter-button convocatoria w-button active"
                  : "filter-button convocatoria w-button"
              }
              onClick={() => setActiveTab("2")}
            >
              2022
            </a>
          </div>
          <div class="w-dyn-list">
            <div role="list" class="convocatoria-collection-list w-dyn-items">
              <div role="listitem" class="w-dyn-item">
                <div class="convocatoria-wrap" divStyles4 >PRESENCIAL / VIRTUAL
                  <div style={divStyles6} class="convocatoria-nombre" color="primary">Seminario Marketing y Comunicación Política para Mujeres</div>
                  <div class="datos-ano"></div>
                  { /* liga y nombre */}
                  { /* <a href="https://docdro.id/85N5Jqd" class="boton w-button" >
                    DESCARGAR CONVOCATORIA 
                  </a><br></br> }
                  { /* liga y nombre */}
                  <a href="https://docdro.id/njN88mU" class="boton w-button">
                    LISTA DE CONVOCATORIA
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* liga de convocatoria para poner acceso pdf name y fecha */}
          <div class="w-dyn-list">
            <div role="list" class="convocatoria-collection-list w-dyn-items">
              <div role="listitem" class="w-dyn-item">
                <div class="convocatoria-wrap" divStyles4 >PRESENCIAL / VIRTUAL
                  <div style={divStyles6} class="convocatoria-nombre" color="primary">Seminaro Mujeres Políticas en Acción</div>
                  <div class="datos-ano"></div>
                  { /* liga y nombre */}
                  <a href="https://docdro.id/jORLXzL" class="boton w-button">
                    DESCARGAR CONVOCATORIA
                  </a>
                </div>
              </div>
            </div>
            <div class="w-dyn-empty">
              <div></div>
            </div>
          </div>
          <div class="w-dyn-list">
            <div role="list" class="convocatoria-collection-list w-dyn-items">
              <div role="listitem" class="w-dyn-item">
                <div class="convocatoria-wrap" divStyles4 >
                  <div style={divStyles6} class="convocatoria-nombre" color="primary"></div>
                  <div class="datos-ano"></div>
                  { /* liga y nombre */}
                  { /*<a href="https://docdro.id/jORLXzL" class="boton w-button">
                    DESCARGAR CONVOCATORIA 
                     </a> */}
                </div>
              </div>
            </div>
            <div class="w-dyn-empty">
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default News;
