import React, { useEffect, useState } from "react";
import Header from "../../components/MainPage/Header";
import foto1 from "../../assets_ppm/images/gallery/enero14/foto1.jpg";
import foto2 from "../../assets_ppm/images/gallery/enero14/foto2.jpg";
import foto3 from "../../assets_ppm/images/gallery/enero14/foto3.jpg";
import foto4 from "../../assets_ppm/images/gallery/enero14/foto4.jpg";
import foto5 from "../../assets_ppm/images/gallery/enero14/foto5.jpg";
import foto6 from "../../assets_ppm/images/gallery/enero14/foto6.jpg";
import foto7 from "../../assets_ppm/images/gallery/enero14/foto7.jpg";
import foto8 from "../../assets_ppm/images/gallery/enero14/foto8.jpg";
import foto9 from "../../assets_ppm/images/gallery/enero14/foto9.jpg";
import foto28 from "../../assets_ppm/images/gallery/enero14/foto28.jpg";
import foto11 from "../../assets_ppm/images/gallery/enero14/foto11.jpg";
import foto12 from "../../assets_ppm/images/gallery/enero14/foto12.jpg";
import foto13 from "../../assets_ppm/images/gallery/enero14/foto13.jpg";
import foto14 from "../../assets_ppm/images/gallery/enero14/foto14.jpg";
import foto15 from "../../assets_ppm/images/gallery/enero14/foto15.jpg";
import foto16 from "../../assets_ppm/images/gallery/enero14/foto16.jpg";
import foto17 from "../../assets_ppm/images/gallery/enero14/foto17.jpg";
import foto18 from "../../assets_ppm/images/gallery/enero14/foto18.jpg";
import foto19 from "../../assets_ppm/images/gallery/enero14/foto19.jpg";
import foto20 from "../../assets_ppm/images/gallery/enero14/foto20.jpg";
import foto21 from "../../assets_ppm/images/gallery/enero14/foto21.jpg";
import foto22 from "../../assets_ppm/images/gallery/enero14/foto22.jpg";
import foto23 from "../../assets_ppm/images/gallery/enero14/foto23.jpg";
import foto24 from "../../assets_ppm/images/gallery/enero14/foto24.jpg";
import foto25 from "../../assets_ppm/images/gallery/enero14/foto25.jpg";
import foto26 from "../../assets_ppm/images/gallery/enero14/foto26.jpg";
import foto27 from "../../assets_ppm/images/gallery/enero14/foto27.jpg";
import image1 from "../../assets_ppm/images/gallery/enero25/foto1.jpg";
import image2 from "../../assets_ppm/images/gallery/enero25/foto2.jpg";
import image3 from "../../assets_ppm/images/gallery/enero25/foto3.jpg";

import CardEvent2 from "../Home/Cardimag1";
import Footer from "../../components/MainPage/Footer";
import { requirePropFactory } from "@mui/material";
import Carousel from "../../components/Gallery/Carousel";
import {
  Row,
  Col,
  Form,
  Img,
  Label,
  Alert,
  Button,
  CardText,
  CardTitle,
  CardHeader,
  Card,
  CardBody,
  FormGroup,
  TabContent,
  TabPane 
} from "reactstrap";


const divStyles = { backgroundColor: "white", fontSize: "2rem", color: "#6cc6ff", textAlign: "center" };

const Galeries = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <Header></Header>

      <div class="seccion wf-section">
        <div class="eventos-wrapper">
          <div class="seccion-nombre">Galeria</div>
          <div class="filter-wrapper" tabs>
            <a
              className={
                activeTab == "1"
                  ? "filter-button eventos w-button active"
                  : "filter-button eventos w-button"
              }
              onClick={() => setActiveTab("1")}
            >
              2022
            </a>
            <a
              className={
                activeTab == "2"
                  ? "filter-button eventos w-button active"
                  : "filter-button eventos w-button"
              }
              onClick={() => setActiveTab("2")}
            >
              2023
            </a>
          </div>
          <div class="w-dyn-list">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Card
                  style={{
                    width: '75rem'
                  }}>

                  <div>
                  
                  <div style={divStyles}>
                  Taller Liderazgo Político con Perspectiva de Género y No Discriminación         
                  </div>               

                   

                    <Row>
                      
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px" }} src={foto1} />
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto2} />
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto3} /> 
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto4} />                                    
                    </Row>
                    <Row>
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px" }} src={foto5} />
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto6} />  
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto7} />  
                    <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto8} />  
                    </Row>
                    <Row>
                      
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px" }} src={foto9} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto28} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto11} /> 
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto12} />                                    
                      </Row>
                      <Row>
                      
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px" }} src={foto13} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto14} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto15} /> 
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto16} />                                    
                      </Row>
                      <Row>
                      
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px" }} src={foto17} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto18} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto19} /> 
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto20} />                                    
                      </Row>
                      <Row>
                      
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px" }} src={foto21} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto22} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto23} /> 
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto24} />                                    
                      </Row>
                      <Row>
                      
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px", marginLeft: "30px" }} src={foto25} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto26} />
                      <img class= "mb-1 col-sm-6 col-md-6 col-xl-4" style={{ width: "250px", height: "200px", marginTop: "20px" }} src={foto27} /> 
                                                        
                      </Row>

                      <div style={divStyles}>
                   Día Naranja Charla Conociendo la Igualdad Sustantiva entre Mujeres y Hombres         
                  </div> 

                      <Row>
                      
                      <CardEvent2 image={image1}></CardEvent2>
                    
                      </Row>


                    </div>
                
                  <div class="w-dyn-list">
                    <div role="list" class="collection-list w-dyn-items">
                      <div role="listitem" class="collection-item-2 w-dyn-item">
                        <div class="datos-wrapper">
                          { /* <div class="datos-imagen"></div>
                          <div class="datos-fecha"></div>
                          <div class="datos-pleca"></div>
                          <div class="datos-categoria"></div>
                          <div class="datos-nombre"></div>
                          <p class="datos-modalidad"></p>
                <p class="datos-ano"></p> */ } <br></br>
                          { /* <a href="/#/registro" class="boton w-button">Registrarme</a> */}
                        </div>
                      </div>
                    </div>
                    <div class="w-dyn-empty">
                      <div>Galeria</div>
                    </div>
                  </div>
                </Card>
              </TabPane>
            </TabContent>
          </div>
        </div>
        <Footer></Footer>
        / </div></>
  );
};
export default Galeries;