import React from "react";
import MAIL_LOGO4 from "../../assets_ppm/images/PPM_Logo_20222x.png"
import LogoFooter from "../../assets_ppm/images/PPM_Logo_20222x.png"
import MAIL_LOGO5 from "../../assets_ppm/images/Mail.svg"
import MAIL_LOGO6 from "../../assets_ppm/images/social-facebook.svg"
import MAIL_LOGO7 from "../../assets_ppm/images/Social-Twitter.svg"
const Footer = (props) => {

  return (
    <>
      <div class="footer wf-section">
        <div class="footer-wrapper">
          <div class="w-layout-grid grid"><img src={MAIL_LOGO4} Loading= "images/PPM_Logo_20222x.png" loading="lazy" />
            <div id="w-node-ddea8399-06a4-1223-5fe9-58f7d5ad8157-d5ad8153" class="footer-secc-wrapper">
              <a href="/#/quienes_somos" class="nav-grid-link footer w-inline-block">
                <div>Quienes somos</div>
              </a>
              <a href="/#/eventos" class="nav-grid-link footer w-inline-block">
                <div>Eventos</div>
              </a>
              <a href="/#/noticias" class="nav-grid-link footer w-inline-block">
                <div>Noticias</div>
              </a>
            </div>
            <div id="w-node-ddea8399-06a4-1223-5fe9-58f7d5ad8161-d5ad8153" class="footer-secc-wrapper">
              <a href="/#/convocatorias" class="nav-grid-link footer w-inline-block">
                <div>Convocatoria</div>
              </a>
              <a href="/#/galerias" class="nav-grid-link footer w-inline-block">
                <div>Galerías</div>
              </a>
              <a href="/#/videos" class="nav-grid-link footer w-inline-block">
                <div>Videos</div>
              </a>
            </div>
            <div id="w-node-ddea8399-06a4-1223-5fe9-58f7d5ad816b-d5ad8153" class="footer-secc-wrapper">
              <a href="/#/contacto" class="nav-grid-link footer w-inline-block">
                <div>Contacto</div>
              </a>
              <a href="/#/red_sorora" class="nav-grid-link footer w-inline-block">
                <div>Red Sorora</div>
              </a>
            </div>
            <div id="w-node-ddea8399-06a4-1223-5fe9-58f7d5ad8172-d5ad8153" class="footer-secc-wrapper redes">
              <a href="mailto:dlevy@pangto.org?subject=Contacto%20Sitio%20Web" class="footer-redes-link w-inline-block"><img src= {MAIL_LOGO5} Loading= "images/Mail.svg" loading="lazy" alt="" class="footer-redes-ico" /></a>
              <a href="https://www.facebook.com/" target="_blank" class="footer-redes-link w-inline-block"><img src= {MAIL_LOGO6} Loading= "images/social-facebook.svg" loading="lazy" alt="" class="footer-redes-ico" /></a>
              <a href="https://twitter.com/ppmgto" target="_blank" class="footer-redes-link w-inline-block"><img src= {MAIL_LOGO7} Loading="images/Social-Twitter.svg" loading="lazy" alt="" class="footer-redes-ico" /></a>
            </div>
          </div>
          <p class="footer-legales">Teléfono: (477) 514 7000 ext. 153 y 154<br></br>Blvd. José María Morelos No. 2055, San Pablo, 37207 León, Gto., México<br />2016 Comité Directivo Estatal Guanajuato PAN</p>
        </div>
      </div>

    </>
  );
};
export default Footer;
