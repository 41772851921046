import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, Card, CardHeader, CardContent, Container } from "@mui/material";
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
const NotFound = (props) => {
  return (
    <>
      <Header></Header>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid container justifyContent="center" alignItems="center">
            <Card>
              <CardHeader title="PAGINA NO ENCONTRADA"></CardHeader>
              <CardContent className="p-0">
                <Container>
                  <Grid container>
                    <Grid md={12} sm={12} lg={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <NavLink
                          className="nav-link"
                          to={{ pathname: "/" }}
                          rel="opener noreferrer"
                        >
                          <>
                            <i className={"fa fa-home"} />
                            {" Ir al Inicio"}
                          </>
                        </NavLink>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </>
  );
};

export default NotFound;
