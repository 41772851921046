import React, { useEffect, useState } from "react";
import Header from "../../components/MainPage/Header";
import CircleCard from "../../components/CircleCard/CircleCard"
import Footer from "../../components/MainPage/Footer";
import Paper from "@mui/material/Paper";
import Carousel from "react-material-ui-carousel";
import image1 from "../../assets_ppm/images/aboutus/Daniela.png";
import image2 from "../../assets_ppm/images/aboutusnew/ABASOLOLeticiaMunguíaMartínez.png";
import image3 from "../../assets_ppm/images/aboutusnew/CDMANUELDOBLADOÁngelesSanchézSanchézPPM.png";
import image4 from "../../assets_ppm/images/aboutusnew/CELAYAMichelleRomeroFranco.png";
import image5 from "../../assets_ppm/images/aboutusnew/COMONFORTBrendaGamezSilva.png";
import image6 from "../../assets_ppm/images/aboutusnew/CORTAZARLizbethVelezHerrera.png";
import image7 from "../../assets_ppm/images/aboutusnew/CUERAMAROClaudiaBuenoMacias.png";
import image8 from "../../assets_ppm/images/aboutusnew/DOLORESHIDALGOLorenaSotoGodinez.png";
import image9 from "../../assets_ppm/images/aboutusnew/GUANAJUATOEvelynMuñoz.png";
import image10 from "../../assets_ppm/images/aboutusnew/IRAPUATODianaAlanisBarroso.png";
import image11 from "../../assets_ppm/images/aboutusnew/JARALDELPROGRESOCarmenRomoCastelo.png";
import image12 from "../../assets_ppm/images/aboutusnew/JERECUAROVeronicaAguilarCervantes.jpg";
import image13 from "../../assets_ppm/images/aboutusnew/PENJAMOAliciaLopezMares.png";
import image14 from "../../assets_ppm/images/aboutusnew/SALAMANCAcoordinadoraClaudiaSanchezEscamilla.jpg";
import image15 from "../../assets_ppm/images/aboutusnew/SALAMANCAKarinaHernandezResendiz.png";
import image16 from "../../assets_ppm/images/aboutusnew/SALVATIERRAMonicaOrtegaLara.jpg";
import image17 from "../../assets_ppm/images/aboutusnew/SANFELIPETessyNegreteGonzalez.png";
import image18 from "../../assets_ppm/images/aboutusnew/SANLUISDELAPAZMinervaUriasRuiz.jpg";
import image19 from "../../assets_ppm/images/aboutusnew/SANMIGUELDEALLENDEMarianaMoraPalacios.jpg";
import image20 from "../../assets_ppm/images/aboutusnew/SANTACRUZDEJUVENTINOROSASKarenAgrazSanchezMendoza.png";
import image21 from "../../assets_ppm/images/aboutusnew/PPM_TARANDACUAO_Melis_Ruíz_García.jpeg";
import image22 from "../../assets_ppm/images/aboutusnew/VALLEDESANTIAGOTeresaToledoMartinez.jpg";
import image23 from "../../assets_ppm/images/aboutusnew/APASEOELGRANDERocioBecerra.jpeg";
import image24 from "../../assets_ppm/images/aboutusnew/ROMITALolitagarcíaArias.jpeg";
import image25 from "../../assets_ppm/images/aboutusnew/PPM_TARIMORO_Gabriela_Hernández_Hurtado.jpeg";
import image26 from "../../assets_ppm/images/aboutusnew/PPM_ACÁMBARO_Gabriela_Acevedo_García.jpeg";
import image27 from "../../assets_ppm/images/aboutusnew/Marta_Segura_Rodriguez_San_Jose_iturbide.jpeg";
import image28 from "../../assets_ppm/images/aboutusnew/Ahyme_Rivera_Diaz_Huanimaro.jpeg";
import image29 from "../../assets_ppm/images/aboutusnew/PPM_ATARJEA_Victoria_Briones_Mata.jpeg";
import image30 from "../../assets_ppm/images/aboutusnew/PPM_XICHÚ_Florina_Zarate_Romero.jpeg";
import image31 from "../../assets_ppm/images/aboutusnew/PPM_LEÓN_Berta_Estela_Hernández_Márquez.jpeg";
import image32 from "../../assets_ppm/images/aboutusnew/PPM_SANTA_CATARINA_Azucena_Gudiño_González.jpeg";
import image33 from "../../assets_ppm/images/aboutusnew/PPM_YURIRIA_Sofia_López_Aguilera.jpeg";
import image34 from "../../assets_ppm/images/aboutusnew/VILLAGRÁN_Alejandra_Monroy_García.jpeg";
import image35 from "../../assets_ppm/images/aboutusnew/Eva_lara_Barajas.jpeg";
import image36 from "../../assets_ppm/images/aboutusnew/PPM_APASEO_EL_ALTO_Audelia_Cárdenas_Herrera.jpeg";
import image37 from "../../assets_ppm/images/aboutusnew/PPM_SAN_DIEGO_DE_LA_UNION_Claudia_Hernandez_Aguilar.jpeg";
import image38 from "../../assets_ppm/images/aboutusnew/PPM_SILAO_Anel_Torres_Rosas.jpeg";
import image39 from "../../assets_ppm/images/aboutusnew/PPM_VICTORIA_Martha_Dorado_Suarez.jpeg";
import image40 from "../../assets_ppm/images/aboutusnew/Virginia_Delgado_Aguilera.jpeg";
import image41 from "../../assets_ppm/images/aboutus/FlorinaZarate.png";
import image42 from "../../assets_ppm/images/aboutus/FlorinaZarate.png";
import image43 from "../../assets_ppm/images/aboutus/FlorinaZarate.png";


import NotImage from "../../assets_ppm/images/aboutus/NotImage.png";

const AboutUs = (props) => {
  
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header></Header>
      <div class="quienes-somos wf-section">
        <div class="somos-wrapper">
          <div class="seccion-nombre centrado">Quienes Somos</div>
          <p class="intro-centrado">
            <div>
            La Secretaria de Promoción Política de la Mujer es la secretaria de Acción Nacional que promueve a las mujeres para que nos preparemos y participemos en la vida pública, pero sobre todo que motivemos a otras mujeres a hacer lo mismo, a participar y desempeñar nuestro liderazgo en los distintos sectores y ámbitos de la sociedad y con ello poder obtener una efectiva igualdad de género y no discriminación. 
           </div><br /><div>
           En PPM Gto estamos conscientes de que uno de los objetivos del Partido Acción Nacional es la formación y el fortalecimiento de la conciencia democrática de las y los mexicanos, por ello, tenemos clara la importancia de formar y capacitar a nuestra militancia con perspectiva de género, con una visión de igualdad sustantiva, donde unidas y unidos construyamos una sociedad igualitaria, donde seamos la mejor opción para las mujeres mexicanas, en la que se vea a Acción Nacional no solo como el mejor partido político para gobernar, sino como el partido político en el que pueden confiar, formarse y participar para mejorar las condiciones de vida de más mujeres.

           </div>

          </p>
          <img
            src="images/firma.png"
            loading="lazy"
            alt=""
            class="somos-firma"
          />
          <div class="seccion-nombre centrado">Directorio</div>
          <div  class="directorio-wrapper">
            <div class="directorio-perfil">
              <div class="directorio-perfil-img"></div>
              <div class="directorio-perfil-nom">Olimpia Zapata Padilla</div>
              <div class="directorio-perfil-cargo">
                Secretaria Estatal
                <br />
                Promoción Política de la Mujer
                <br />
                PAN Guanajuato
              </div>
              <div>
                <a
                  href="mailto:dlevy@pangto.org?subject=Contacto%20sitio%20web%20de%20PPM"
                  class="directorio-perfil-redes w-inline-block"
                >
                  <img
                    src="images/Mail-With-Circle.svg"
                    loading="lazy"
                    alt=""
                  />
                </a>
                <a
                  href="https://twitter.com/oly_zapata"
                  class="directorio-perfil-redes w-inline-block"
                >
                  <img
                    src="images/Twitter-With-Circle.svg"
                    loading="lazy"
                    alt=""
                  />
                </a>
              </div>
            </div>
           

           

            <CircleCard type={1} image={image1} fullname={"Daniela Levy Ramírez"} position={"Coordiandora"} location={"PAN Guanajuato"}></CircleCard>

          <div class="directorio-perfil-wrap">

            <CircleCard type={2} image={image2} fullname={"Leticia Munguia Martínez"} position={"Secretaria Municipal"} location={"PAN Abasolo"}></CircleCard>
            <CircleCard type={2} image={image26} fullname={"Gabriela Acevedo García"} position={"Secretaria Municipal"} location={"PAN Acámbaro"}></CircleCard>
            <CircleCard type={2} image={image36} fullname={"Audelia Cardenas Herrera"} position={"Secretaria Municipal"} location={"PAN Apaseo el Alto"}></CircleCard>
            <CircleCard type={2} image={image23} fullname={"Rocio Becerra Guzman"} position={"Secretaria Municipal"} location={"PAN Apaseo el Grande"}></CircleCard>
            <CircleCard type={2} image={image29} fullname={"Victoria Briones Mata"} position={"Secretaria Municipal"} location={"PAN Atarjea"}></CircleCard>

          </div>
          <div class="directorio-perfil-wrap">

            <CircleCard type={2} image={NotImage} fullname={""} position={"Secretaria Municipal"} location={"PAN Manuel Doblado"}></CircleCard>
            <CircleCard type={2} image={image4} fullname={"Fernanda Michelle Romero Franco"} position={"Secretaria Municipal"} location={"PAN Celaya"}></CircleCard>
            <CircleCard type={2} image={image5} fullname={"Maria Brenda Gámez Silva"} position={"Secretaria Municipal"} location={"PAN Comonfort"}></CircleCard>
            <CircleCard type={2} image={image6} fullname={"María Lizbeth Velez Herrera"} position={"Secretaria Municipal"} location={"PAN Cortazar"}></CircleCard>
            <CircleCard type={2} image={NotImage} fullname={""} position={"Secretaria Municipal"} location={"PAN Coroneo"}></CircleCard>

            
          </div>
          <div class="directorio-perfil-wrap">
          
          <CircleCard type={2} image={image7} fullname={"Claudia Gabriela Bueno Macías"} position={"Secretaria Municipal"} location={"PAN Cuerámaro"}></CircleCard>
          <CircleCard type={2} image={NotImage} fullname={"Fabiola Ríos Galicia"} position={"Secretaria Municipal"} location={"PAN Doctor Mora"}></CircleCard>
          <CircleCard type={2} image={image8} fullname={"Lorena Adriana Soto Godínez"} position={"Secretaria Municipal"} location={"PAN Dolores Hidalgo"}></CircleCard>
          <CircleCard type={2} image={image9} fullname={"Karla Evelyn Muñoz Ramírez"} position={"Secretaria Municipal"} location={"PAN Guanajuato"}></CircleCard>
          <CircleCard type={2} image={image28} fullname={"Ahyme Rivera Díaz"} position={"Secretaria Municipal"} location={"PAN Huanímaro"}></CircleCard>


        </div>
          <div class="directorio-perfil-wrap">

          <CircleCard type={2} image={image10} fullname={"Diana Patricia Alanís Barroso"} position={"Secretaria Municipal"} location={"PAN Irapuato"}></CircleCard>
          <CircleCard type={2} image={image11} fullname={"Maria del Carmen Romo Castelo"} position={"Secretaria Municipal"} location={"PAN Jaral del Progreso"}></CircleCard>
          <CircleCard type={2} image={image12} fullname={"Veronica Aguilar Cervantes"} position={"Secretaria Municipal"} location={"PAN Jerecuaro"}></CircleCard>
          <CircleCard type={2} image={image31} fullname={"Berta Estela del Carmen Hernández Hernández"} position={"Secretaria Municipal"} location={"PAN León"}></CircleCard>
          <CircleCard type={2} image={NotImage} fullname={"Nayeli Viviana Céspedes Tejeda"} position={"Coordinadora"} location={"PAN León"}></CircleCard>


            
          </div>
          <div class="directorio-perfil-wrap">

          <CircleCard type={2} image={NotImage} fullname={"María Guadalupe Vega Guzmán"} position={"Secretaria Municipal"} location={"PAN Moroleón"}></CircleCard>
          <CircleCard type={2} image={NotImage} fullname={""} position={"Secretaria Municipal"} location={"PAN Ocampo"}></CircleCard>
          <CircleCard type={2} image={image13} fullname={"Alicia López Mares"} position={"Secretaria Municipal"} location={"PAN Penjamo"}></CircleCard>
          <CircleCard type={2} image={image40} fullname={"Virginia Delgado Aguilera"} position={"Secretaria Municipal"} location={"PAN Pueblo Nuevo"}></CircleCard>
          <CircleCard type={2} image={image35} fullname={"Eva Lara Abarajas"} position={"Secretaria Municipal"} location={"PAN Purísima del Rincón"}></CircleCard>

          
            
          </div>
          <div class="directorio-perfil-wrap">

          <CircleCard type={2} image={image24} fullname={"Ma. Dolores García Arias"} position={"Secretaria Municipal"} location={"PAN Romita"}></CircleCard>
          <CircleCard type={2} image={image14} fullname={"Claudia Sanchez Escamilla"} position={"Secretaria Municipal"} location={"PAN Salamanca"}></CircleCard>
          <CircleCard type={2} image={image39} fullname={"Martha Alicia Dorado Suarez"} position={"Secretaria Municipal"} location={"PAN Victoria"}></CircleCard>
          <CircleCard type={2} image={image16} fullname={"Monica Ortega Lara"} position={"Secretaria Municipal"} location={"PAN Salvatierra"}></CircleCard>
          <CircleCard type={2} image={image37} fullname={"Claudia Elizabeth Hernandez Aguilar"} position={"Secretaria Municipal"} location={"PAN San Diego de la Unión"}></CircleCard>

            
          </div>
          <div class="directorio-perfil-wrap">

          <CircleCard type={2} image={image17} fullname={"Maria Esther Negrete Gonzalez"} position={"Secretaria Municipal"} location={"PAN San Felipe"}></CircleCard>
          <CircleCard type={2} image={NotImage} fullname={"Paula Daniela Alvarez Trejo"} position={"Secretaria Municipal"} location={"PAN San Francisco del Rincón"}></CircleCard>
          <CircleCard type={2} image={image27} fullname={"Marta Maria Segura Rodríguez"} position={"Secretaria Municipal"} location={"PAN San José Iturbide"}></CircleCard>
          <CircleCard type={2} image={image18} fullname={"Micaela Minerva Urias Ruiz"} position={"Secretaria Municipal"} location={"PAN San Luis de la Paz"}></CircleCard>
          <CircleCard type={2} image={image19} fullname={"Mariana Mora Palacios"} position={"Secretaria Municipal"} location={"PAN San Miguel de Allende"}></CircleCard>


            
          </div>
          <div class="directorio-perfil-wrap">

          <CircleCard type={2} image={image32} fullname={"Azucena"} position={"Secretaria Municipal"} location={"PAN Santa Catarina"}></CircleCard>
          <CircleCard type={2} image={image20} fullname={"Karen Agraz Sanchez Mendoza"} position={"Secretaria Municipal"} location={"PAN Santa Cruz de Juventino Rosas"}></CircleCard>
          <CircleCard type={2} image={NotImage} fullname={"Ma. del Carmen Lopez Nava"} position={"Secretaria Municipal"} location={"PAN Santiago Maravatio"}></CircleCard>
          <CircleCard type={2} image={image38} fullname={"Priscila Anel Torres Rosas"} position={"Secretaria Municipal"} location={"PAN Silao"}></CircleCard>
          <CircleCard type={2} image={image21} fullname={"Melis Anais Ruíz García"} position={"Secretaria Municipal"} location={"PAN Tarandacuao"}></CircleCard>


            
          </div>
          <div class="directorio-perfil-wrap">

          <CircleCard type={2} image={image25} fullname={"Miriam Gabriela Hernandez Huertado"} position={"Secretaria Municipal"} location={"PAN Tarimoro"}></CircleCard>
          <CircleCard type={2} image={NotImage} fullname={""} position={"Secretaria Municipal"} location={"PAN Tierra Blanca"}></CircleCard>
          <CircleCard type={2} image={NotImage} fullname={""} position={"Secretaria Municipal"} location={"PAN Uriangato"}></CircleCard>
          <CircleCard type={2} image={image22} fullname={"María Teresa Toledo Martínez"} position={"Secretaria Municipal"} location={"PAN Valle de Santiago"}></CircleCard>
          <CircleCard type={2} image={image34} fullname={"Alejandra Monroy García"} position={"Secretaria Municipal"} location={"PAN Villagran"}></CircleCard>
            
            
          </div>
          <div class="directorio-perfil-wrap">

          <CircleCard type={2} image={image30} fullname={"María Florina Zarate Romero"} position={"Secretaria Municipal"} location={"PAN Xichú"}></CircleCard>
          <CircleCard type={2} image={image33} fullname={"Sofia López Aguilera"} position={"Secretaria Municipal"} location={"PAN Yuriria"}></CircleCard>

               
          </div>
        </div>
        <img
          src="images/Spot.jpg"
          loading="lazy"
          sizes="400px"
          srcset="images/Spot-p-500.jpeg 500w, images/Spot.jpg 668w"
          alt=""
          class="spot"
        />
        <img
          src="images/Spot.jpg"
          loading="lazy"
          sizes="400px"
          srcset="images/Spot-p-500.jpeg 500w, images/Spot.jpg 668w"
          alt=""
          class="spot der"
        />
      </div>
      <Footer></Footer>
      </div>
    </>
  );
};
export default AboutUs;
