

import React, { useEffect, useState } from "react";
const CircleCard = ({image,fullname,position,location,type}) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
    

<div className={type===2?"directorio-perfil _20":"directorio-perfil"}>
<div class="" style={
  {
    width: '154px',
    height: '154px',
    marginBottom: '20px',
    borderRadius: '200px',
    backgroundImage: `url(${image})`,
    backgroundPosition: '50% 50%',
    backgroundSize: '200px',
  }


  
} ></div>
<div class="directorio-perfil-nom">{fullname}</div>
<div class="directorio-perfil-cargo">
{position}
  <br />
  Promoción Política de la Mujer
  <br />
 {location}
</div>
<div>
  <a
    href="mailto:dlevy@pangto.org?subject=Contacto%20sitio%20web%20de%20PPM"
    class="directorio-perfil-redes w-inline-block"
  >
    <img src="images/Mail-With-Circle.svg" loading="lazy" alt=""
 />
  </a>
  <a
    href="https://twitter.com/oly_zapata"
    class="directorio-perfil-redes w-inline-block"
  >
    <img
      src="images/Twitter-With-Circle.svg"
      loading="lazy"
      alt=""
    />
  </a>
</div>
</div>
</>
);
}

export default CircleCard;
